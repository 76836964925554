import * as React from "react"
import Seo from "src/components/seo"
import AppLayout from "src/components/app/app-layout"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {graphql} from "gatsby"

function FaqSection(faqItem) {
  const itemElements = faqItem.items.map(item => {
    return (
      <FaqListItem link={item.faqLink} title={item.faqTitle}/>
    )
  });

  return (
    <>
      <h3>{faqItem.headingTitle}</h3>
      <ul>{itemElements}</ul>
    </>
  )
}

const FaqListItem = ({link, title}) => {
  return (
    <li>
      <a href={link}>{title}
      </a>
    </li>
  );
}

const Faq = () => {
  const {t} = useTranslation();
  const title = t("faq.title");

  const topFaqList = [
    {faqLink: "/akamaru/faq/0004", faqTitle: t("faq.currentPurchasingStatus.question")},
    {faqLink: "/akamaru/faq/0009", faqTitle: t("faq.freePlay.question")},
    {faqLink: "/akamaru/faq/0011", faqTitle: t("faq.bonusPlay.question")},
  ];

  const categorizedFaqList = [
    {
      headingTitle: t("faq.subTitle.subscribes"),
      items: [
        {faqLink: "/akamaru/faq/0004", faqTitle: t("faq.currentPurchasingStatus.question")},
        {faqLink: "/akamaru/faq/0009", faqTitle: t("faq.freePlay.question")},
        {faqLink: "/akamaru/faq/0011", faqTitle: t("faq.bonusPlay.question")},
      ],
    },
    {
      headingTitle: t("faq.subTitle.restorement"),
      items: [
        {faqLink: "/akamaru/faq/0006", faqTitle: t("faq.restoreFailure.question")},
        {faqLink: "/akamaru/faq/0012", faqTitle: t("faq.restoreButton.question")},
        {faqLink: "/akamaru/faq/0005", faqTitle: t("faq.removeAndRestore.question")},
      ],
    },
    {
      headingTitle: t("faq.subTitle.unSubscribes"),
      items: [
        {faqLink: "/akamaru/faq/0010", faqTitle: t("faq.freeTrial.question")},
        {faqLink: "/akamaru/faq/0007", faqTitle: t("faq.howToUnsubscribe.question")},
        {faqLink: "/akamaru/faq/0008", faqTitle: t("faq.playAfterUnsubscribe.question")},
      ],
    },
    {
      headingTitle: t("faq.subTitle.others"),
      items: [
        {faqLink: "/akamaru/faq/0001", faqTitle: t("faq.aboutAkamaru.question")},
        {faqLink: "/akamaru/faq/0002", faqTitle: t("faq.ageRange.question")},
        {faqLink: "/akamaru/faq/0003", faqTitle: t("faq.noReplay.question")},
      ],
    },
  ];

  return (
    <AppLayout>
      <Seo title={title} isIndex={false}/>
      <article className="container page-article-main privacy-page">
        <h1>{title}</h1>

        <section>
          <h2>{t("faq.subTitle.topFaqs")}</h2>
          <ul>
            {
              topFaqList.map(faq => {
                return FaqListItem({link: faq.faqLink, title: faq.faqTitle});
              })
            }
          </ul>

          <h2>{t("faq.categories")}</h2>
          {
            categorizedFaqList.map(categorizedFaq => {
              return FaqSection(categorizedFaq);
            })
          }

        </section>
      </article>
    </AppLayout>
  );
}

export default Faq

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
